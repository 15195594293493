<template>
    <div>
        <b-modal v-model="showModal" hide-footer centered @hidden="$emit('close')">
            <template v-slot:modal-header>
                <div class="w-100 d-flex justify-content-between align-items-center py-2">
                    <div class="d-flex align-items-center">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:height="'20'" v-bind:width="'20'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1.324 7.23A8.98 8.98 0 013.22 3.97a.533.533 0 01.573-.143l2.04.726a1.065 1.065 0 001.403-.807l.39-2.125a.531.531 0 01.411-.423 9.11 9.11 0 013.78 0 .53.53 0 01.412.423l.39 2.125a1.063 1.063 0 001.402.807l2.04-.726a.533.533 0 01.574.142c.853.94 1.5 2.051 1.893 3.26a.528.528 0 01-.162.567l-1.653 1.397a1.054 1.054 0 000 1.614l1.653 1.397c.165.14.228.363.162.567a8.98 8.98 0 01-1.893 3.26.532.532 0 01-.573.142l-2.04-.726a1.065 1.065 0 00-1.403.807l-.39 2.125a.53.53 0 01-.413.423 9.101 9.101 0 01-3.779 0 .531.531 0 01-.412-.423l-.389-2.125a1.061 1.061 0 00-1.403-.807l-2.04.726a.532.532 0 01-.573-.142 8.98 8.98 0 01-1.893-3.26.528.528 0 01.162-.567l1.653-1.397a1.056 1.056 0 000-1.614L1.488 7.796a.528.528 0 01-.162-.567h-.002zM7.3 10a2.625 2.625 0 105.251 0 2.625 2.625 0 00-5.25 0z" fill="#35375B" opacity=".8"/></svg>
                        <span class="ml-2 modal-title body-color">{{ staticText.autoJoinBotLabel }} </span>
                    </div>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:height="'16'" v-bind:width="'16'" v-bind:class="'cursor-pointer'" v-bind:svg-inline="''" @click="closeShareModal" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8 1.6C4.48 1.6 1.6 4.48 1.6 8c0 3.52 2.88 6.4 6.4 6.4 3.52 0 6.4-2.88 6.4-6.4 0-3.52-2.88-6.4-6.4-6.4zm3.2 8.704l-.896.896L8 8.896 5.696 11.2l-.896-.896L7.104 8 4.8 5.696l.896-.896L8 7.104 10.304 4.8l.896.896L8.896 8l2.304 2.304z" fill="#AAABBA"/></svg>

                </div>
            </template>
            <template v-slot:default>
                <div class="mx-1">
                    
                   <div class="rule-type-label mb-3 body-color">
                    {{ staticText.autoJoinBotRuleLabel }}
                   </div>

                    <!-- auto join settings -->
                    <b-form-group class="bubble-menu__card__radio-group">
                        <b-form-radio
                            v-for="(item, index) in ruleTypeList"
                            :key="index"
                            v-model="keywordRuleType"
                            :value="item"
                            @change="updateAutoJoinSetting"
                            class="custom-radio-btn-label body-color mb-3"
                        >
                        {{ item.title }}
                        <div v-if="item.id=='keyword'&&keywordRuleType && keywordRuleType.id=='keyword'">
                            <div class="body-color">
                                {{ staticText.aboutKeywordRule }}
                            </div>
                            <div class="d-flex my-3">
                                <b-form-input
                                    v-model="keyword" type="text" :placeholder="staticText.keywordPlaceholder"
                                    class="keyword-input" @keyup.enter="addKeyword"
                                />
                                <div class="cursor-pointer d-flex align-items-center add-btn px-3 ml-3 body-color"
                                    @click="addKeyword"
                                >
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:height="'20'" v-bind:width="'20'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10 5v10m5-5H5" stroke="#7F8197" stroke-width="2" stroke-linecap="round"/></svg>
                                    <span class="ml-2">{{ staticText.addText }}</span>
                                </div>
                            </div>
                            <div class="rule-keywords mt-2" v-if="keywordList.length">
                                <bao-pill v-for="(item, idx) in keywordList"
                                        :key="idx"
                                        :title="item"
                                        :id="idx"
                                        @close="removeItem($event)"
                                    />
                            </div>
                        </div>
                        </b-form-radio>
                    </b-form-group>
                    <div role="button" class="d-flex" @click="$emit('showMeetingsModal')">
                        See all upcoming meetings
                    </div>
                    
                </div>
            </template>
            
        </b-modal>
    </div>
</template>
<script>
import BaoPill from "@/apps/base/BaoPill"

export default{
    name: "AutoJoinSettingModal",
    components: {
        BaoPill
    },
    props: {
        autoJoinBotRule: {
            type: Object,
            default: () => {}
        },
        calendarType: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            showModal: true,
            keyword: null,
            keywordRuleType: null,
            keywordList: [],
            intialKeywords: [],
            staticTextDefault: {
                allUpcomingMeetings: "All upcoming meetings",
                meetingsWithSpecificKeywords: "Meetings with specific keywords",
                manuallySelectedMeetings: "Manually selected meetings",
                autoJoinBotLabel: "Auto-joining Co-Pilot for your Video Meetings",
                aboutKeywordRule: "Define the keywords that the Co-Pilot searches for in your meetings' title & description in order to (not) join your video meetings independently:",
                keywordPlaceholder: "Your Keyword",
                addText: "Add",
                noKeywordInfo: "Important: If no keywords are given, the Co-Pilot will join EVERY video meeting as long as the Co-Pilot is toggled on.",
                autoJoinBotRuleLabel: "Co-Pilot will join automatically, if",
                cancelLabel: "Cancel",
                saveSettingLabel: "Save Changes"
            },
        }
    },
    computed: {
        ruleTypeList() {
            return [
                {
                    "id": "all",
                    "title": this.staticText.allUpcomingMeetings
                },
                {
                   "id": "keyword",
                   "title": this.staticText.meetingsWithSpecificKeywords
                },
                {
                   "id": "manual",
                   "title": this.staticText.manuallySelectedMeetings
                }
            ]
        },
        staticText () {
            return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
        },
        checkAutoJoinRule() {
            if(!this.keywordRuleType){
                return true
            }
            return false
        }
    },
    methods: {
        isAnyChangeInKeywords(arr1, arr2) {
            return !(arr1.length === arr2.length && arr1.slice().sort().every((val, index) => val === arr2.slice().sort()[index]));
        },
        closeShareModal() {
            this.showModal = false
        },
        addKeyword() {
            if(!!this.keyword){
                this.keywordList.push(this.keyword)
                this.keyword = null
            }
            this.updateAutoJoinSetting()
        },
        removeItem(index) {
            this.keywordList.splice(index, 1)
            this.updateAutoJoinSetting()
        },
        updateAutoJoinSetting() {
            const data = {
                auto_join_bot: true,
                calendar_type: this.calendarType,
                auto_join_bot_rule: {
                    auto_join_rule: {
                        type: this.keywordRuleType.id,
                        list_of_words: this.keywordRuleType.id=='keyword' ? this.keywordList: []
                    }
                }
            }
            this.$emit("saveAutoJoinSetting", data)
            // this.closeShareModal()
        },
        loadAutoJoinSettingData() {
            if(this.autoJoinBotRule.auto_join_rule){
                this.keywordList = this.autoJoinBotRule.auto_join_rule.list_of_words
                this.intialKeywords = [...this.keywordList]
                this.keywordRuleType = this.ruleTypeList.find(item => item.id===this.autoJoinBotRule.auto_join_rule.type)
            }
        }
    },
    mounted() {
        this.loadAutoJoinSettingData()
    }
}

</script>
<style lang="scss" scoped>

.rule-keywords{
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}
.add-btn{
    background-color: #2A2D520F;
    border-radius: 20px;
}
.keyword-input{
    box-shadow: 4px 4px 20px 0px #0000000D;
    border-radius: 12px;
}
.custom-radio-btn-label{
    :deep(.custom-control-label){
        width: 100% !important;
    }
}
.modal-title{
    font-weight: 700;
}
.imp-label{
    font-size: 14px;
    line-height: 16.8px;
}
.rule-type-label{
    color: #2A2D52;
    font-size: 18px;
    line-height: 21.6px;
}
.body-color{
    color: #555775;
}
</style>