import axios from "axios"

export const exchangeAuthCodeForTokens = async (payload) => {
  // create meeting bot for recording meeting data
  const apiUrl = "/api/oauth_token/set_tokens"
  const resp = await axios.post(apiUrl, payload)
  return resp
}

export const getUserCalendar = async (params) => {
  // create meeting bot for recording meeting data
  const apiUrl = "/api/user_calendar/get_user_calendars"
  const resp = await axios.get(apiUrl, { params })
  return resp
}

export const revokeOauthToken = async (payload) => {
  // create meeting bot for recording meeting data
  const apiUrl = "/api/oauth_token/revoke_token"
  const resp = await axios.post(apiUrl, payload)
  return resp
}

export const setAutoJoinBotSetting = async (payload) => {
  // set auto join bot setting
  const apiUrl = "/api/user_calendar/update_setting"
  const resp = await axios.post(apiUrl, payload)
  return resp
}

export const createCalendarEvent = async (eventData) => {
  // create calendar event in google calendar using user's primary calendar
  const apiUrl = "/api/user_calendar/add_calendar_event"
  const resp = await axios.post(apiUrl, eventData)
  return resp
}

export const getCalendarEvents = async (params) => {
  // get calendar events
  const apiUrl = "/api/user_calendar/get_calendar_events"
  const resp = await axios.get(apiUrl, { params })
  return resp
}

export const toggleBotStatus = async (payload) => {
  // toggle bot status
  const apiUrl = "/api/user_calendar/toggle_bot_status"
  const resp = await axios.post(apiUrl, payload)
  return resp
}
