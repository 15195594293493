<template>
    <div>
        <b-modal v-model="showModal" scrollable hide-footer centered @hidden="$emit('close')" @shown="onModalShown">
            <template v-slot:modal-header>
                <div class="w-100 d-flex justify-content-between align-items-center py-2">
                    <div class="d-flex align-items-center">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:height="'20'" v-bind:width="'20'" v-bind:svg-inline="''" v-bind:class="'cursor-pointer'" @click="$emit('openAutoJoinModal')" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M15 18l-6-6 6-6" stroke="#555775" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        <span class="ml-2 modal-title body-color">Upcoming Meetings </span>
                    </div>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:height="'16'" v-bind:width="'16'" v-bind:class="'cursor-pointer'" v-bind:svg-inline="''" @click="showModal=false" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8 1.6C4.48 1.6 1.6 4.48 1.6 8c0 3.52 2.88 6.4 6.4 6.4 3.52 0 6.4-2.88 6.4-6.4 0-3.52-2.88-6.4-6.4-6.4zm3.2 8.704l-.896.896L8 8.896 5.696 11.2l-.896-.896L7.104 8 4.8 5.696l.896-.896L8 7.104 10.304 4.8l.896.896L8.896 8l2.304 2.304z" fill="#AAABBA"/></svg>

                </div>
            </template>
            <template v-slot:default>
                <div class="scroll-container" ref="modalBody">
                    <div  class="d-flex flex-column ">
                        <div class="d-flex align-items-center">
                            <b-form-input
                                v-model="queryTerm"
                                :placeholder="'Type meeting name or URL'"
                                :autofocus="true"
                                size="5"
                                class="pr-5"
                                @input="searchCalendarEvents()"
                            >
                            </b-form-input>
                            <!-- search icon  -->
                            <span class="search-icon"
                                @click="searchCalendarEvents()"
                            >
                                <progress-indicator
                                    v-if="loading"
                                    :showLoadingLabel="false"
                                    small
                                    :tooltip="true"
                                >
                                </progress-indicator>
                                <img v-else src="../../../../public/img/icons/search-icon.svg"/>
                            </span>
                        </div>
                        <div v-if="loading" class="pt-5 pb-4">
                            <progress-indicator />
                        </div>
                        <div v-else-if="!meetingData.length"
                            class="d-flex pt-4 pb-3 justify-content-center">
                            <span>
                                No meeting found
                            </span>
                        </div>
                        <div 
                            v-else
                            class="d-flex justify-content-between flex-column mt-3"
                            v-for="(item, idx) in meetingData"
                            :key="idx"
                        >
                            <div v-if="item.event_item" class="d-flex justify-content-between">
                                <div class="d-flex justify-content-between">
                                    <div v-if="item.toggling_bot_status">
                                        <progress-indicator
                                            :showLoadingLabel="false"
                                            small
                                            :tooltip="true"
                                        >
                                        </progress-indicator>
                                    </div>
                                    <div
                                        v-else-if="item.is_bot_scheduled"
                                        class="toggle-btn"
                                        role="button"
                                        @click="toggleSelection(item)"
                                    >
                                        <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M9.6.594a.644.644 0 010 .934l-5.486 5.28a.706.706 0 01-.97 0L.4 4.167a.643.643 0 010-.935.706.706 0 01.97 0L3.63 5.405l5-4.811a.706.706 0 01.971 0H9.6z" fill="#5D514B" fill-opacity=".4"/></svg>
                                    </div>
                                    <div v-else
                                        class="toggle-btn"
                                        @click="toggleSelection(item)"
                                    >
                                    </div>
        
                                    <div class="d-flex flex-column ml-2">
                                        <div class="meeting-title">
                                            {{item.title}}
                                        </div>
                                        <div class="meeting-timing py-1 px-1">
                                            {{item.formated_start_time}}
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex ml-2 copilot-status" v-if="item.is_bot_scheduled">
                                    Copiot Added
                                </div>
                            </div>
                            <div class="hori-line my-3"></div>
                        </div>
                        <div class="target-div">
                            <progress-indicator
                                :showLoadingLabel="false"
                            >
                            </progress-indicator>
                        </div>
                    </div>
                </div>
            </template>
        </b-modal>
        
    </div>
</template>

<script>

import ProgressIndicator from "@/apps/base/ProgressIndicator"
import { getCalendarEvents, toggleBotStatus } from "@/apps/user/integrations/api"

export default{
    name: 'ShowMeetingsModal',
    components: {
        ProgressIndicator
    },
    props: {
        calendarType: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            showModal: true,
            recordingConsent: null,
            isSelected: false,
            loading: false,
            queryTerm: "",
            meetingData: [],
            googleCalendarEventPageToken: ""
        }
    },
    async mounted() {
        this.searchCalendarEvents()
        await this.sleep(1000)
        // set the observer on visibility
        const scrollableParent = document.querySelector('.scroll-container');
        const targetDiv = document.querySelector('.target-div');
        this.onElementVisibilityChange(targetDiv, scrollableParent, (isVisible) => {
            if (isVisible) {
                this.searchCalendarEvents(this.googleCalendarEventPageToken)
            }
        });
    },
    methods: {
        onElementVisibilityChange(element, parent, callback) {
            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            // Element is visible
                            callback(true);
                        } else {
                            // Element is not visible
                            callback(false);
                        }
                    });
                },
                {
                    root: parent,
                    threshold: 0,
                }
            );

            observer.observe(element);
        },
        async toggleSelection(item) {
            const payload = {
                event_id: item.event_id,
                schedule_bot: !item.is_bot_scheduled,
                calendar_type: this.calendarType,
                start_at: item.start_at,
                end_at: item.end_at
            }
            item.toggling_bot_status = true
            await toggleBotStatus(payload)
            item.is_bot_scheduled = !item.is_bot_scheduled
            item.toggling_bot_status = false
        },
        async searchCalendarEvents (page_token="") {
            if (this.loading) {
                return
            }
            if (!page_token){
                this.googleCalendarEventPageToken = ""
            } else {
                this.loading = false
            }
            const params = {
                query_term: this.queryTerm,
                calendar_type: this.calendarType,
                timezone_offset_minutes: -1*(new Date().getTimezoneOffset()),
                page_token: page_token
            }
            const { data } = await getCalendarEvents(params)
            const events_list = data.events_list
            this.googleCalendarEventPageToken = data.next_page_token
            events_list.forEach(item => {
                item.formated_start_time = this.formatDateTime(item.start_at)
                item.event_item = true,
                item.toggling_bot_status = false
            })
            if (page_token) {
                this.meetingData = [...this.meetingData, ...events_list]
            } else {
                this.meetingData = events_list
            }
            this.loading = false
        },
        formatDateTime (dateTime) {
            const date = new Date(dateTime);
            const optionsDate = { month: 'short', day: 'numeric' };
            const optionsTime = { hour: 'numeric', minute: 'numeric', hour12: true };
            const formattedDate = new Intl.DateTimeFormat('en-US', optionsDate).format(date);
            const formattedTime = new Intl.DateTimeFormat('en-US', optionsTime).format(date);
            return `${formattedDate} / ${formattedTime}`;
        }
    }
}

</script>

<style lang="scss" scoped>

.copilot-status {
    background-color: #FF56321F;
    font-family: Nimbus Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    color: #FF5632;
    border-radius: 100px;
    height: max-content;
    padding: 6px 8px 6px 8px;
}
.meeting-title {
    color: #555775;
    font-size: 16px;
    font-weight: 400;
    line-height: 12px;
}
.meeting-timing {
    background-color: #F0F0F0;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    width: fit-content;
    margin-top: 8px;
    border-radius: 4px;
}
.search-icon {
    color: $slate40;
    position: relative;
    margin-left: -40px;
    cursor: pointer;
}

.toggle-btn {
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF !important;
    border: 1px solid $slate40;
    border-radius: 5px;
    width: 20px;
    height: 20px;
    svg {
      path {
        fill: $slate;
        fill-opacity: 1;
      }
    }
}
.hori-line {
    height: 1px;
    background-color: #F0F0F0;
}
.scroll-container {
    max-height: 400px; /* Set an appropriate height */
    overflow-y: auto; /* Enable vertical scrolling */
}
.target-div {
    background-color: #FFFFFF;
}
</style>